import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import whiteLogo from '../Assets/images/white-logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import './Dashboard.scss';

const Dashboard = () => {
    const [answer, setAnswer] = useState("");
    const [loading, setLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [question, setQuestion] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [username, setUsername] = useState('');
    const [datasets, setDatasets] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState('');
    const [documentList, setDocumentList] = useState([]);
    const [sources, setSources] = useState([]);
    const [pdfUrl, setPdfUrl] = useState('');
    const selectedModel = 'gpt-4o';
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);


    useEffect(() => {
        const fetchUserAndDatasets = async () => {
            try {
                const userResponse = await axios.get(`${window.location.origin}/api/get-user`, {
                    withCredentials: true,
                });
                if (userResponse.data.username) {
                    setUsername(userResponse.data.username);
                }

                const datasetsResponse = await axios.get(`${window.location.origin}/api/datasets`, {
                    withCredentials: true,
                });
                if (datasetsResponse.data && Array.isArray(datasetsResponse.data)) {
                    setDatasets(datasetsResponse.data);
                    if (datasetsResponse.data.length > 0) {
                        setSelectedDataset(datasetsResponse.data[0]);
                        await loadDocumentList(datasetsResponse.data[0]);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchUserAndDatasets();
    }, []);

    useEffect(() => {
        if (selectedDataset) {
            loadDocumentList(selectedDataset);
        }
    }, [selectedDataset]);

    const loadDocumentList = async (datasetName) => {
        console.log("Loading document list for dataset:", datasetName);
        try {
            const response = await axios.post(`${window.location.origin}/api/get-document-list`, {
                dataset_name: datasetName
            });

            setDocumentList([]);

            if (response.data && Array.isArray(response.data)) {
                const processedDocs = response.data.map(doc => ({
                    document_id: doc.document_id,
                    filename: doc.filename.split('/').pop(), // Extract filename from path
                    status: doc.status || 'processed'
                }));
                console.log("Processed docs:", processedDocs);
                setDocumentList(processedDocs);
            }
        } catch (error) {
            console.error("Error loading documents:", error);
        }
    };
    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const askQuestion = async (questionText) => {
        setLoading(true);
        setAnswer("");
        try {
            const response = await axios.post(`${window.location.origin}/api/get-answer`, {
                question: questionText,
                model: selectedModel,
                dataset_name: selectedDataset,
            });

            setChatHistory(prev => [questionText, ...prev]);
            setAnswer(formatAnswer(response.data.answer));
            setSources(response.data.sources);

            if (response.data.sources && response.data.sources.length > 0) {
                const firstSource = response.data.sources[0];
                loadPdf(firstSource.document_id, firstSource.page_number);
            }
        } catch (error) {
            console.error("Error:", error);
            setAnswer("An error occurred while processing your request.");
        } finally {
            setLoading(false);
        }
    };

    const handleCustomPrompt = async (prompt) => {
        setQuestion(prompt); // Set the current question to display
        await askQuestion(prompt);
    };

    const handleNewChat = () => {
        setAnswer("");
        setChatHistory([]); // Clear chat history when starting a new chat
        setQuestion(""); // Clear the current question
        setInputValue(""); // Clear the input field
        setSources([]);
        setPdfUrl('');
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value); // Update inputValue instead of question
    };

    const handleAskQuestion = (e) => {
        if (e) e.preventDefault(); // Prevent any default form action.

        if (inputValue.trim()) {
            setQuestion(inputValue);
            askQuestion(inputValue);
            setInputValue("");
        }
    };

    const handleDatasetChange = (e) => {
        const dataset = e.target.value;
        setSelectedDataset(dataset);
    };

    const loadPdf = (document_id, pageNumber) => {
        try {
            console.log("Loading PDF for document:", document_id, "page:", pageNumber);
            const pdfBody = document.querySelector("#pdfContainer .card-body");
            if (pdfBody) {
                const pdfViewer = document.createElement("iframe");
                pdfViewer.id = "pdfViewer";
                pdfViewer.style.width = "100%";
                pdfViewer.style.height = "600px";
                pdfViewer.style.border = "none";

                // Update PDF source to use the webserver endpoint
                const timestamp = new Date().getTime();
                pdfViewer.src = `${window.location.origin}/api/pdf/${document_id}?t=${timestamp}#page=${pageNumber}`;

                pdfBody.innerHTML = '';
                pdfBody.appendChild(pdfViewer);
            }
        } catch (error) {
            console.error("Error loading PDF:", error);
        }
    };

    const formatAnswer = (answer) => {
        answer = answer.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
        answer = answer.replace(/\r\n/g, "\n");
        let paragraphs = answer.split(/(?:\n\s*){2,}|(?<=\d\.)\n(?=[-*])/);
        return paragraphs
            .map((paragraph) => {
                paragraph = paragraph.trim();
                if (/^\s*(\d+\.\s|[-*])/.test(paragraph)) {
                    let items = paragraph
                        .split("\n")
                        .filter((item) => item.trim() !== "");
                    let listItems = items.map((item) => {
                        let cleanedItem = item
                            .trim()
                            .replace(/^\s*(\d+\.\s|[-*])\s*/, "");
                        return `<li class="mb-2">${cleanedItem || item.trim()}</li>`;
                    });
                    return `<ul class="mb-3 list-unstyled">${listItems.join("")}</ul>`;
                } else {
                    return `<p class="mb-3">${paragraph}</p>`;
                }
            })
            .join("");
    };

    return (
        <div className="h-100">
            <div className="dashboard-area">
                <div className="container-fluid container-lg">
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="ds-content">
                                {/* Chevron Button */}


                                <div className="ds-header"></div>
                                <div className="dashboard-content">
                                    {/* Sidebar */}
                                    {(isDesktop || isSidebarOpen) && (
                                        <div
                                            className={`ds-sidebar ${!isDesktop
                                                ? isSidebarOpen
                                                    ? 'mobile-open'
                                                    : 'mobile-closed'
                                                : ''
                                                }`}
                                        >
                                            {/* Logo container */}
                                            {isDesktop && (
                                                <div className="logo-container">
                                                    <img
                                                        src={whiteLogo}
                                                        alt="InsightPath Logo"
                                                        className="mb-4"
                                                    />
                                                </div>
                                            )}

                                            {/* New Chat Button */}
                                            <button className="add-btn" onClick={handleNewChat}>
                                                + New Chat
                                            </button>

                                            {/* Chat History */}
                                            <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
                                                {chatHistory.map((chat, index) => (
                                                    <li
                                                        key={index}
                                                        className={index === 0 ? 'active' : ''}
                                                        onClick={() => handleCustomPrompt(chat)}
                                                        style={{ padding: '8px 10px', cursor: 'pointer' }}
                                                    >
                                                        {chat}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    {/* Overlay */}
                                    {!isDesktop && isSidebarOpen && (
                                        <div className="overlay" onClick={() => setIsSidebarOpen(false)}></div>
                                    )}

                                    {/* Main Content Area */}
                                    <div className="ds-manage">
                                        <div className="ds-manage-header d-flex align-items-center">

                                            {!isDesktop && (
                                                <button
                                                    className="sidebar-toggle"
                                                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                                                >
                                                    {isSidebarOpen ? (
                                                        <ChevronLeft color="white" />
                                                    ) : (
                                                        <ChevronRight color='#4a4ac7' />
                                                    )}
                                                </button>
                                            )}
                                            {!isSidebarOpen && (
                                                <div className="ds-header-nav d-flex flex-grow-1 align-items-center">
                                                    <ul className="ds-header-links d-flex">
                                                        <li><Link to="">Generate</Link></li>
                                                        <li><Link to="/dashboard" className='active'>Q & A</Link></li>
                                                        <li><Link to="/manage">Manage</Link></li>
                                                    </ul>
                                                    <div className="ms-auto">
                                                        {username}
                                                    </div>
                                                </div>

                                            )}
                                        </div>
                                        <div className="ds-manage-body text-start">

                                            <div>

                                                {/* Question Input Container */}
                                                <div className="question-input-container mt-4 d-flex" style={{ alignItems: 'center' }}>
                                                    <input
                                                        type="text"
                                                        value={inputValue}
                                                        onChange={handleInputChange}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleAskQuestion(e);
                                                            }
                                                        }}
                                                        placeholder="Enter your question"
                                                        className="form-control"
                                                    />
                                                    <button
                                                        id="askButton"
                                                        type="button" // Add this to ensure the button doesn't submit a form
                                                        onClick={(e) => handleAskQuestion(e)} // Pass event to prevent default action
                                                        className="btn btn-primary"
                                                    >
                                                        <i className="fas fa-search me-2"></i>
                                                        {loading ? 'Processing...' : 'Ask'}
                                                    </button>

                                                </div>

                                                {question && (
                                                    <div className="question-summary p-3 mt-4">
                                                        {question}
                                                    </div>
                                                )}

                                                {!loading && !answer && (
                                                    <div className="d-flex gap-3 ds-manage-input-bx">
                                                        <button onClick={() => handleCustomPrompt("What are the capabilities of the NGC CLI?")}>
                                                            What are the capabilities of the NGC CLI?
                                                        </button>
                                                        <button onClick={() => handleCustomPrompt("What are the options for renewing an NVIDIA Enterprise Support contract?")}>
                                                            What are the options for renewing an NVIDIA Enterprise Support contract?
                                                        </button>
                                                    </div>
                                                )}

                                                {loading && (
                                                    <div className="text-center my-4">
                                                        <div className="spinner-border text-primary" role="status">
                                                            <span className="visually-hidden">Processing your question...</span>
                                                        </div>
                                                        <p className="mt-2">Processing your question...</p>
                                                    </div>
                                                )}



                                                {/* Answer Container */}
                                                {answer && (
                                                    <div className="card mt-4">

                                                        <div className="card-body">
                                                            <div
                                                                className="answer-text"
                                                                dangerouslySetInnerHTML={{ __html: answer }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Sources List */}
                                                {sources.length > 0 && (
                                                    <div id="sourcesContainer" className="card mt-4">
                                                        <div className="card-header text-white">Sources</div>
                                                        <div className="card-body">
                                                            <ul id="sourcesList" className="list-group list-group-flush">
                                                                {sources.map((source, index) => (
                                                                    <li key={index} className="list-group-item">
                                                                        <a href="#" onClick={(e) => { e.preventDefault(); loadPdf(source.document_id, source.page_number); }}>
                                                                            {`${source.filename.split('/').pop()} (Page ${source.page_number})`}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* PDF Viewer */}
                                                <div id="pdfContainer" className="card mt-4">
                                                    <div className="card-body p-0">
                                                        {pdfUrl ? (
                                                            <iframe id="pdfViewer"></iframe>
                                                        ) : (
                                                            <div className="text-center p-5">
                                                                <h5>PDF Viewer</h5>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;