import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import whiteLogo from '../Assets/images/white-logo.png';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import './Manage.scss';

const Manage = () => {
    const [datasets, setDatasets] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState('');
    const [newDatasetName, setNewDatasetName] = useState('');
    const [newDatasetDescription, setNewDatasetDescription] = useState('');
    const [documentList, setDocumentList] = useState([]);
    const [isAddDatasetModalOpen, setIsAddDatasetModalOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchUserAndDatasets = async () => {
            try {
                const userResponse = await axios.get(`${window.location.origin}/api/get-user`, {
                    withCredentials: true,
                });
                if (userResponse.data.username) {
                    setUsername(userResponse.data.username);
                }

                const datasetsResponse = await axios.get(`${window.location.origin}/api/datasets`, {
                    withCredentials: true,
                });

                if (datasetsResponse.data && Array.isArray(datasetsResponse.data)) {
                    setDatasets(datasetsResponse.data);
                    if (datasetsResponse.data.length > 0) {
                        setSelectedDataset(datasetsResponse.data[0]);
                        await loadDocumentList(datasetsResponse.data[0]);
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchUserAndDatasets();
    }, []);

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const loadDocumentList = async (datasetName) => {
        try {
            const response = await axios.post(`${window.location.origin}/api/get-document-list`, {
                dataset_name: datasetName
            });

            if (response.data && Array.isArray(response.data)) {
                const processedDocs = response.data.map(doc => ({
                    document_id: doc.document_id,
                    filename: doc.filename,
                    status: doc.status,
                    lastModified: doc.metadata?.lastModified || new Date().toISOString(),
                    pageCount: doc.metadata?.pageCount || 'N/A'
                }));
                console.log('Processed documents:', processedDocs); // For debugging
                setDocumentList(processedDocs);
            }
        } catch (error) {
            console.error("Error loading documents:", error);
        }
    };

    const handleDatasetChange = (e) => {
        const dataset = e.target.value;
        setSelectedDataset(dataset);
        loadDocumentList(dataset);
    };

    const handleAddDataset = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${window.location.origin}/api/datasets`, {
                name: newDatasetName,
                description: newDatasetDescription
            });

            setDatasets([...datasets, response.data]);
            setNewDatasetName('');
            setNewDatasetDescription('');
            setIsAddDatasetModalOpen(false);
        } catch (error) {
            console.error("Error adding dataset:", error);
        }
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        // Update the file name display
        const fileDisplay = e.target.parentElement.querySelector('.file-chosen');
        if (fileDisplay) {
            fileDisplay.textContent = file.name;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('dataset_name', selectedDataset);

        try {
            await axios.post(`${window.location.origin}/api/add-document`, formData);
            await loadDocumentList(selectedDataset);
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <div className="h-100">
            <div className="dashboard-area">
                <div className="container-fluid container-lg">
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div className="ds-content">
                                <div className="dashboard-content">
                                    {/* Sidebar */}
                                    {(isDesktop || isSidebarOpen) && (
                                        <div
                                            className={`ds-sidebar ${!isDesktop
                                                ? isSidebarOpen
                                                    ? 'mobile-open'
                                                    : 'mobile-closed'
                                                : ''
                                                }`}
                                        >
                                            {isDesktop && (
                                                <div className="logo-container">
                                                    <img src={whiteLogo} alt="InsightPath Logo" className="mb-4" />
                                                </div>
                                            )}
                                            <button className="add-btn">
                                                + New Chat
                                            </button>
                                        </div>
                                    )}

                                    {/* Overlay */}
                                    {!isDesktop && isSidebarOpen && (
                                        <div className="overlay" onClick={() => setIsSidebarOpen(false)}></div>
                                    )}

                                    {/* Main Content Area */}
                                    <div className="ds-manage">
                                        <div className="ds-manage-header d-flex align-items-center">
                                            {!isDesktop && (
                                                <button
                                                    className="sidebar-toggle"
                                                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                                                >
                                                    {isSidebarOpen ? (
                                                        <ChevronLeft color="white" />
                                                    ) : (
                                                        <ChevronRight color='#4a4ac7' />
                                                    )}
                                                </button>
                                            )}
                                            {!isSidebarOpen && (
                                                <div className="ds-header-nav d-flex flex-grow-1 align-items-center">
                                                    <ul className="ds-header-links d-flex">
                                                        <li><Link to="">Generate</Link></li>
                                                        <li><Link to="/dashboard">Q & A</Link></li>
                                                        <li><Link to="/manage" className='active'>Manage</Link></li>
                                                    </ul>
                                                    <div className="ms-auto">
                                                        {username}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="ds-manage-body">
                                            <div className="controls-row">
                                                <div className="control-item select-area">
                                                    <select
                                                        className="form-select"
                                                        value={selectedDataset}
                                                        onChange={handleDatasetChange}
                                                    >
                                                        <option value="" disabled>Select Dataset</option>
                                                        {['Nvidia', 'Small', 'Big'].map((value) => (
                                                            <option key={value} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="control-item button-area">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => setIsAddDatasetModalOpen(true)}
                                                    >
                                                        Add New Dataset
                                                    </button>
                                                </div>

                                                <div className="control-item upload-area">
                                                    <label className="custom-file-input">
                                                        <input
                                                            type="file"
                                                            accept=".pdf"
                                                            onChange={handleFileUpload}
                                                            className="d-none"
                                                        />
                                                        <div className="file-input-display">
                                                            <span className="btn-choose">Upload File</span>
                                                            <span className="file-chosen">No file chosen</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>

                                            {/* Document List Card */}
                                            <div className="card">
                                                <div className="card-header text-white document-list-header">
                                                    Document List
                                                </div>
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="name-column">Name</th>
                                                                    <th className="date-column">Last Modified</th>
                                                                    <th className="pages-column">Number of Pages</th>
                                                                    <th className="status-column">Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {documentList.map((doc) => (
                                                                    <tr key={doc.document_id}>
                                                                        <td className="name-column">
                                                                            {doc.filename}
                                                                        </td>
                                                                        <td className="date-column">{formatDate(doc.lastModified)}</td>
                                                                        <td className="pages-column">{doc.pageCount}</td>
                                                                        <td className="status-column">
                                                                            <span className={`status-badge status-${doc.status.toLowerCase()}`}>
                                                                                {doc.status}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Dataset Modal */}
            {
                isAddDatasetModalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <button
                                className="modal-close"
                                onClick={() => setIsAddDatasetModalOpen(false)}
                            >
                                ×
                            </button>
                            <h2>Add New Dataset</h2>
                            <form onSubmit={handleAddDataset} className="mt-4">
                                <div className="mb-3">
                                    <label className="form-label">Dataset Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newDatasetName}
                                        onChange={(e) => setNewDatasetName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Description</label>
                                    <textarea
                                        className="form-control"
                                        value={newDatasetDescription}
                                        onChange={(e) => setNewDatasetDescription(e.target.value)}
                                    />
                                </div>
                                <div className="d-flex justify-content-end gap-2">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => setIsAddDatasetModalOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Add Dataset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default Manage;
