import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./Components/Layout";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Manage from "./Pages/Manage.jsx";

function App() {
  // Initialize Google Analytics only once
  useEffect(() => {
    ReactGA.initialize("G-L4XR74KCH3");
  }, []);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
      // Track page views
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      });
    }, [location]);

    useEffect(() => {
      setTimeout(() => {
        document.documentElement.scrollTo(0, 0);
      }, 0);
    }, [location.pathname, location.search]);
    return children;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route
              index
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/manage" element={<Manage />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
